import React from "react"
import Layout from '../../components/layout-en'
import Head from '../../components/head'
import { Link } from 'gatsby'
import { /*StaticQuery,*/ graphql } from "gatsby"
import '../../styles/article-category.css'
import Pagination from '../../components/pagination'

export const query = graphql`
  query NewsQuery {
      
    allStrapiArticles(sort: {fields: published_date, order: DESC}, filter: {draft: {}, article_category: {category_name: {eq: "News"}}}) {
      edges {
        node {
          id
          slug
          title
          subtitle
          cover {
            id
            url
          }
          category {
            category_name
          }
          article_category {
            category_name
          }
          published_date(formatString: "DD MMMM YYYY")
        }
      }
    }
  }
`

class Home extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      allNews : [],
      tags : ["All"],
      selectedTag : "All",
      isFiltered : false,
      searchArticle : "",
      currentPage : 1,
      articlesPerPage: 5
    };
  }

  componentDidMount(){
    this.setState({allNews : this.props.data.allStrapiArticles.edges})
  }

  addTag(tag){
    if(tag == "All"){
      this.setState({selectedTag: "All"});
      this.setState({isFiltered:false});
    } else {
      this.setState({selectedTag: tag});
      this.setState({isFiltered:true});
    }
  }

  handleInput = (e) => {
    this.setState({searchArticle : e.target.value});
  }
  
  paginate(pageNumber){
    this.setState({currentPage : pageNumber})
  }
  

  render(){
    const getNews = this.state.allNews;
    const headlineArticles = getNews.slice(0, 5);
    const notHeadlineArticles = getNews.slice(5, getNews.length);
    const leftArticles = headlineArticles.slice(0, 1);
    const rightArticles = headlineArticles.slice(1, 5);

    const tagArray = ["All"];
    for (let i = 0; i < getNews.length; i++){
      if (tagArray.indexOf(getNews[i].node.category.category_name) == -1){
        tagArray.push(getNews[i].node.category.category_name);
      }
    }

    let filteredArticle = getNews.filter((article) => {
      return article.node.category.category_name.includes(this.state.selectedTag);
    });

    let searchedArticles = getNews.filter((article) => {
      return article.node.title.toLowerCase().includes(this.state.searchArticle.toLowerCase());
    });

    //Get current articles
    const indexOfLastArticle = this.state.currentPage * this.state.articlesPerPage;
    const indexOfFirstArticle = indexOfLastArticle - this.state.articlesPerPage;
    const currentArticles = notHeadlineArticles.slice(indexOfFirstArticle, indexOfLastArticle);

    /*console.log(tagArray)
    console.log("is filtered " + this.state.isFiltered)
    console.log("selected Tag")
    console.log(this.state.selectedTag)
    console.log("Filtered Article")
    console.log(filteredArticle)*/
    return(
        <Layout>
          <Head title = "Blog"/>
          <div className = "blog-page-container">
            <div className = "blog-page-header">
              <div className = "blog-page-title">News</div>
              <div className = "blog-page-subTitle">Agriculture info, tips & trick and trivia. Learn about agriculture in a fun way</div>
              <div className = "blog-search-container">
                <form>
                        
                  <input 
                    type = "text" 
                    name = "keyword"
                    onChange = {this.handleInput}
                    placeholder = "Search blog posts in News..."
                    id = "form-search"/>
                
                </form>
                

              </div>
            </div>


            <div
              //Cek apakah search form terisi, jika tidak terisi, munculkan page seperti normal 
              style = {{display : this.state.searchArticle !== "" ? "none" : "inline-block"}}
              className = "article-category-page-main-section">
              <div
                //Cek apakah melakukan filter artikel berdasarkan tag. Jika iya, maka sembunyikan headline 
                style = {{display : this.state.isFiltered == true ? "none" : "inline-block"}} 
                className = "article-category-page-headline-section">
                <ul className = "blog-card-container">
                  <div id = "left-headline-card-container">
                    {leftArticles.map((article, i) => (
                        <li key = {article.node.id}  className = "left-headline-card">
                          
                            <Link to = {`/blog/${article.node.article_category.category_name}/${article.node.category.category_name}/${article.node.slug}`} className = "blog-card-link">

                              {article.node.cover.map(cover_data => (
                                  <img key = {cover_data.id} src = {`${cover_data.url}`} alt = "article-image" className = "blog-card-image" width="100%"/>
                              ))}

                              <div className = "blog-card-title">{article.node.title}</div>

                              <div className = "blog-card-info">

                                <div className = "blog-card-tag">{article.node.category.category_name}</div>
                                
                                <div className = "blog-card-create-date">{article.node.published_date}</div>
                                
                              </div>

                            </Link>
                        </li>
                    ))}
                  </div>
                  <div id = "right-headline-card-container">
                    {rightArticles.map((article, i) => (
                        <li key = {article.node.id}  className = "right-headline-card">
                          
                            <Link to = {`/blog/${article.node.article_category.category_name}/${article.node.category.category_name}/${article.node.slug}`} className = "blog-card-link">

                              {article.node.cover.map(cover_data => (
                                  <img key = {cover_data.id} src = {`${cover_data.url}`} alt = "article-image" className = "blog-card-image" width="100%"/>
                              ))}

                              <div className = "blog-card-title">{article.node.title}</div>

                              <div className = "blog-card-info">

                                <div className = "blog-card-tag">{article.node.category.category_name}</div>
                                
                                <div className = "blog-card-create-date">{article.node.published_date}</div>
                                
                              </div>

                            </Link>
                        </li>
                    ))}
                  </div>
                </ul>
                
                <div className = "blog-section-divider" />
              </div>         


              <div className = "article-category-page-body-section">

                <div className = "article-category-page-tags-section">
                  <div className = "article-category-page-tags-section-title">Tags</div>
                  <div className = "article-category-page-tags-container">
                    {tagArray.map((tag, i) => (
                      <div 
                        className = {`article-category-page-tag ${this.state.selectedTag !== tag ? "" : " active-tag"}`} 
                        key = {i} 
                        to = {`/blog/${tag}`}
                        onClick = {() => this.addTag(tag)} 
                      >
                          {tag}
                      </div>
                      
                    ))}
                  </div>
                  
                </div>

                <ul
                  //Cek apakah melakukan filter artikel berdasarkan tag. Jika iya, maka sembunyikan kumpulan artikel "all"
                  style = {{display : this.state.isFiltered == true ? "none" : "inline-block"}} 
                  className = "article-category-page-blog-card-container">
                    {currentArticles.map(document => (
                        <div key = {document.node.id} className = "article-category-page-blog-card">
                        {document.node.cover.map(cover_data => (
                            <Link to = {`/blog/${document.node.article_category.category_name}/${document.node.category.category_name}/${document.node.slug}`}><img src = {`${cover_data.url}`} alt = "article-image" width="100%" className = "article-category-page-card-image"/></Link>
                        ))}
                        <div className = "article-category-page-card-info">
                          <Link to = {`/blog/${document.node.article_category.category_name}/${document.node.category.category_name}/${document.node.slug}`} className = "article-category-page-card-title">{document.node.title}</Link>
                          <div className = "article-category-page-card-subTitle">{document.node.subtitle}</div>
                          <div className = "article-category-page-card-footer">
                            <div className = "article-category-page-card-category">{document.node.article_category.category_name}</div>
                            <div className = "article-category-page-card-category">{document.node.category.category_name}</div>
                            <div className = "article-category-page-card-create-date">{document.node.published_date}</div>
                          </div>
                        </div>
                      </div>
                    ))}                    

                  <Pagination 
                    articlesPerPage = {this.state.articlesPerPage} 
                    totalArticles = {notHeadlineArticles.length}
                    paginate = {this.paginate.bind(this)}
                    activePage = {this.state.currentPage} />
                </ul>

                <ul 
                  //Cek apakah melakukan filter artikel berdasarkan tag. Jika iya, maka munculkan hasil kumpulan artikel berdasarkan tag
                  style = {{display : this.state.isFiltered !== true ? "none" : "inline-block"}}
                  className = "article-category-page-blog-card-container">
                    {filteredArticle.map(document => (
                        <div key = {document.node.id} className = "article-category-page-blog-card">
                        {document.node.cover.map(cover_data => (
                            <Link to = {`/blog/${document.node.article_category.category_name}/${document.node.category.category_name}/${document.node.slug}`}><img src = {`${cover_data.url}`} alt = "article-image" width="100%" className = "article-category-page-card-image"/></Link>
                        ))}
                        <div className = "article-category-page-card-info">
                          <Link to = {`/blog/${document.node.article_category.category_name}/${document.node.category.category_name}/${document.node.slug}`} className = "article-category-page-card-title">{document.node.title}</Link>
                          <div className = "article-category-page-card-subTitle">{document.node.subtitle}</div>
                          <div className = "article-category-page-card-footer">
                            <div className = "article-category-page-card-category">{document.node.article_category.category_name}</div>
                            <div className = "article-category-page-card-category">{document.node.category.category_name}</div>
                            <div className = "article-category-page-card-create-date">{document.node.published_date}</div>
                          </div>
                        </div>
                      </div>
                    ))}
                </ul>

              </div>
            </div>

            <div
              //Ini muncul kalau pengguna menggunakan fitur search
              style = {{display : this.state.searchArticle === "" ? "none" : "inline-block"}} 
              className = "blog-card-search-result">
              <ul className = "blog-card-container">
                  {searchedArticles.map(document => (
                      <li key = {document.node.id}  className = "blog-card">
                        
                          <Link to = {`/blog/${document.node.article_category.category_name}/${document.node.category.category_name}/${document.node.slug}`} className = "blog-card-link">
                         
                            {document.node.cover.map(cover_data => (
                                <img key = {cover_data.id} src = {`${cover_data.url}`} alt = "article-image" className = "blog-card-image" width="100%"/>
                            ))}

                            <div className = "blog-card-title">{document.node.title}</div>

                            <div className = "blog-card-info">

                              <div className = "blog-card-tag">{document.node.article_category.category_name}</div>
                              
                              <div className = "blog-card-create-date">{document.node.published_date}</div>
                              
                            </div>

                          </Link>
                      </li>
                  ))}
              </ul>
            </div>

          </div>
        </Layout>
    ) 
  }
}

export default Home;

